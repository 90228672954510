<template>
  <v-container
    fluid
    class="fill-height forbidden-container">
    <ForbiddenOwl id="owl-403"/>
    <br>
    <h1 class="forbidden-title">
      {{ $t('forbidden.title') }}
    </h1>
    <CyNotification
      :title="$t('forbidden.text')"
      :content="$tFind(`routes.403.${permissionsKey}`)"/>
    <CyButton
      variant="secondary"
      theme="accent"
      class="mt-4"
      :to="redirectRoute">
      {{ $t('forbidden.buttonText') }}
    </CyButton>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ForbiddenOwl from '@/assets/images/owls/403forbidden.svg'

export default {
  name: 'CyPageForbidden',
  components: {
    ForbiddenOwl,
  },
  props: {
    backRouteTo: {
      type: String,
      default: '',
    },
    permissionsKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'isOrgSelected',
    ]),
    backRouteName () {
      const { orgCanonical, backRouteTo: name } = this
      const params = { orgCanonical }
      const backRoute = this.$router.resolve({ name, params })
      const backRouteExists = backRoute.href !== '/'
      return backRouteExists && this.$cycloid.permissions.canDisplayRoute(backRoute)
        ? name
        : null
    },
    redirectRoute () {
      const { orgCanonical, isOrgSelected, backRouteName } = this
      const params = { orgCanonical }
      if (!isOrgSelected) return { name: 'organizations' }
      return backRouteName
        ? { name: backRouteName, params }
        : { name: 'dashboard', params }
    },
  },
  i18n: {
    messages: {
      en: {
        title: '403',
        forbidden: {
          buttonText: 'Fly, you fools!',
          text: 'Access forbidden',
          title: 'You sh-owl not pass!',
        },
      },
      es: {
        title: '403',
        forbidden: {
          buttonText: '¡Corred insensatos!',
          text: 'Acceso prohibido',
          title: '¡No pasarán!',
        },
      },
      fr: {
        title: '403',
        forbidden: {
          buttonText: 'Fuyez, pauvres fous !',
          text: 'Accès interdit',
          title: 'Vous ne passerez pas !',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.forbidden-title {
  color: get-color("secondary");
}

.forbidden-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#owl-403 {
  width: 400px;
  transform-origin: center;

  .svg-container {
    isolation: isolate;
  }

  .teal {
    fill: get-color("secondary");
  }

  .dark-blue {
    fill: get-color("primary");
  }

  .robe-shadow,
  .belt-shadow,
  .back-grey {
    fill: #404040;
  }

  .white {
    fill: get-color("white");
  }

  .dark-orange {
    fill: #ef5c2f;
  }

  .light-orange {
    fill: #f69931;
  }

  .brown {
    fill: #87363c;
  }

  .black {
    fill: #231f20;
  }

  .wing-shadow,
  .robe {
    fill: #606161;
  }

  .wing-shadow,
  .robe-shadow {
    opacity: 0.25;
  }

  .wing-shadow,
  .belt-shadow {
    mix-blend-mode: multiply;
  }

  .sword-hilt {
    fill: #121e1e;
  }

  .dark-grey {
    fill: #4d5756;
  }

  .light-grey-1 {
    fill: #c2c1c1;
  }

  .light-grey-2 {
    fill: #a4a5a5;
  }

  .belt-shadow {
    opacity: 0.13;
  }

  .belt {
    stroke: #525252;
    fill: #9f9f9f;
    stroke-miterlimit: 10;
  }

  .belt-buckle {
    stroke-width: 2px;
    stroke-linecap: round;
    stroke: #d7d6d6;
    fill: none;
    stroke-linejoin: round;
  }

  .stick-light-brown {
    fill: #9c682f;
  }

  .stick-dark-brown {
    opacity: 0.48;
    fill: #6b421e;
  }

  .hat-grey {
    fill: #686a6b;
  }
}
</style>
